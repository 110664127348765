import { axios } from '@/utils/request'

/**
 * 查詢流程定義
 *
 * @author yubaoshan
 * @date 2020/6/9 12:29
 */
export function flowableDefinitionPage (parameter) {
  return axios({
    url: '/flowableDefinition/page',
    method: 'get',
    params: parameter
  })
}

/**
 * 部署流程定義
 *
 * @author yubaoshan
 * @date 2020/6/9 12:29
 */
export function flowableCategoryDeploy (parameter) {
  return axios({
    url: '/flowableDefinition/deploy',
    method: 'post',
    data: parameter
  })
}

/**
 * 刪除流程定義，根據版本刪除，級聯刪除流程實例和相關任務
 *
 * @author yubaoshan
 * @date 2020/6/9 12:29
 */
export function flowableDefinitionDelete (parameter) {
  return axios({
    url: '/flowableDefinition/delete',
    method: 'post',
    data: parameter
  })
}

/**
 * 導出流程文件
 *
 * @author yubaoshan
 * @date 2020/6/9 12:29
 */
export function flowableDefinitionExport (parameter) {
  return axios({
    url: '/flowableDefinition/export',
    method: 'get',
    params: parameter,
    responseType: 'blob'
  })
}

/**
 * 映射流程定義，將已部署的流程映射到模型
 *
 * @author yubaoshan
 * @date 2020/6/9 12:29
 */
export function flowableDefinitionMapping (parameter) {
  return axios({
    url: '/flowableDefinition/mapping',
    method: 'post',
    data: parameter
  })
}

/**
 * 掛起流程定義
 *
 * @author yubaoshan
 * @date 2020/6/9 12:29
 */
export function flowableDefinitionSuspend (parameter) {
  return axios({
    url: '/flowableDefinition/suspend',
    method: 'post',
    data: parameter
  })
}

/**
 * 激活流程定義
 *
 * @author yubaoshan
 * @date 2020/6/9 12:29
 */
export function flowableDefinitionActive (parameter) {
  return axios({
    url: '/flowableDefinition/active',
    method: 'post',
    data: parameter
  })
}

/**
 * 流程定義的流程圖
 *
 * @author yubaoshan
 * @date 2020/6/9 12:29
 */
export function flowableDefinitionTrace (parameter) {
  return axios({
    url: '/flowableDefinition/trace',
    method: 'get',
    params: parameter
  })
}

/**
 * 流程定義的用戶任務節點，用於跳轉時選擇節點
 *
 * @author yubaoshan
 * @date 2020/6/9 12:29
 */
export function flowableDefinitionUserTasks (parameter) {
  return axios({
    url: '/flowableDefinition/userTasks',
    method: 'get',
    params: parameter
  })
}
