//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { buttonList, buttonAdd, buttonEdit, buttonDelete } from '@/api/modular/flowable/buttonManage'
import { sysDictTypeDropDown } from '@/api/modular/system/dictManage'
import { flowableDefinitionUserTasks } from '@/api/modular/flowable/defenitionManage'
/**
 * 全選變量設定
 */
const addPlainOptions = []
const defaultCheckedList = []
export default {
  data () {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 15 }
      },
      visible: false,
      tableLoading: false,
      visibleButton: {},
      actData: [],
      UserTasks: [],
      // 新增
      visibleAdd: false,
      addLoading: false,
      addForm: this.$form.createForm(this),
      // 新增多選按鈕
      addCheckedList: defaultCheckedList,
      addIndeterminate: true,
      addCheckAll: false,
      addPlainOptions,
      // 編輯參數
      visibleEdit: false,
      editLoading: false,
      editForm: this.$form.createForm(this),
      // 查詢參數
      queryParam: {},
      // 表頭
      columns: [],
      columnsButtonTitle: [],
      loadData: [],
      textData: []
    }
  },
  methods: {
    /**
     * 其他界面打開此界面初始化方法
     */
    buttonIndex (button) {
      this.visibleButton = button
      this.queryParam.processDefinitionId = button.id
      this.getButtonList()
      this.addAction()
      this.getDictData()
      this.flowableDefinitionUserTasks()
    },
    /**
     * 初始化列表數據
     */
    addAction () {
      this.columns.push({ title: '節點名稱', dataIndex: 'actName' })
      this.columnsButtonTitle.push(
        { title: '提交', dataIndex: 'submitBtn', scopedSlots: { customRender: 'button_yn' } },
        { title: '保存', dataIndex: 'saveBtn', scopedSlots: { customRender: 'button_yn' } },
        { title: '退回', dataIndex: 'backBtn', scopedSlots: { customRender: 'button_yn' } },
        { title: '轉辦', dataIndex: 'turnBtn', scopedSlots: { customRender: 'button_yn' } },
        { title: '指定', dataIndex: 'nextBtn', scopedSlots: { customRender: 'button_yn' } },
        { title: '委托', dataIndex: 'entrustBtn', scopedSlots: { customRender: 'button_yn' } },
        { title: '終止', dataIndex: 'endBtn', scopedSlots: { customRender: 'button_yn' } },
        { title: '追蹤', dataIndex: 'traceBtn', scopedSlots: { customRender: 'button_yn' } },
        { title: '掛起', dataIndex: 'suspendBtn', scopedSlots: { customRender: 'button_yn' } },
        { title: '跳轉', dataIndex: 'jumpBtn', scopedSlots: { customRender: 'button_yn' } },
        { title: '加簽', dataIndex: 'addSignBtn', scopedSlots: { customRender: 'button_yn' } },
        { title: '減簽', dataIndex: 'deleteSignBtn', scopedSlots: { customRender: 'button_yn' } }
      )
      this.columnsButtonTitle.forEach(item => {
        this.columns.push(item)
        addPlainOptions.push(item.dataIndex)
      })
      if (this.hasPerm('flowableButton:edit') || this.hasPerm('flowableButton:delete')) {
        this.columns.push({
          title: '操作',
          width: '150px',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' }
        })
      }
    },
    /**
     * 獲取列表數據
     */
    getButtonList () {
      this.tableLoading = true
      buttonList(this.queryParam).then((res) => {
        this.tableLoading = false
        if (res.success) {
          this.loadData = res.data
        } else {
          this.$message.error(res.message)
        }
      })
    },
    /**
     * 獲取是否字典數據
     */
    getDictData () {
      sysDictTypeDropDown({ code: 'yes_or_no' }).then((res) => {
        this.textData = res.data
      })
    },
    /**
     * 獲取活動節點下拉框數據
     */
    flowableDefinitionUserTasks () {
      flowableDefinitionUserTasks({ id: this.visibleButton.id }).then((res) => {
        this.UserTasks = res.data
      })
    },
    /**
     * 翻譯列表及表單是否
     */
    textFilter (text) {
      // eslint-disable-next-line eqeqeq
      const values = this.textData.filter(item => item.code == text)
      if (values.length > 0) {
        return values[0].value
      }
    },
    /**
     * 關閉此整個界面
     */
    handleCancel () {
      this.loadData = []
      this.columns = []
      this.columnsButtonTitle = []
    },
    /**
     * 打開新增框
     */
    handleAdd () {
      this.visibleAdd = true
      setTimeout(() => {
        this.addForm.setFieldsValue({ processDefinitionId: this.visibleButton.id })
      }, 100)
    },
    /**
     * 關閉新增框
     */
    handleCancelAdd () {
      this.addCheckedList = []
      this.addForm.resetFields()
      this.visibleAdd = false
      this.addCheckAll = false
      this.addIndeterminate = false
    },
    /**
     * 新增提交
     */
    handAddSubmit () {
      const { addForm: { validateFields } } = this
      this.addLoading = true
      validateFields((errors, values) => {
        if (!errors) {
          addPlainOptions.forEach(item => {
            values[item] = 'N'
          })
          if (this.addCheckedList.length > 0) {
            this.addCheckedList.forEach(item => {
              values[item] = 'Y'
            })
          }
          buttonAdd(values).then((res) => {
            this.addLoading = false
            if (res.success) {
              this.$message.success('新增成功')
              this.getButtonList()
              this.handleCancelAdd()
            } else {
              this.$message.error('新增失敗：' + res.message)
            }
          })
        } else {
          this.addLoading = false
        }
      })
    },
    /**
     * 打開編輯框
     */
    handleEdit (record) {
      this.visibleEdit = true
      setTimeout(() => {
        this.editForm.setFieldsValue(
          {
            id: record.id,
            processDefinitionId: record.processDefinitionId,
            actId: record.actId.toString(),
            actName: record.actName
          }
        )
      }, 100)
      addPlainOptions.forEach(item => {
        if (record[item] === 'Y') {
          this.addCheckedList.push(item)
        }
      })
      this.addIndeterminate = !!this.addCheckedList.length && this.addCheckedList.length < addPlainOptions.length
      this.addCheckAll = this.addCheckedList.length === addPlainOptions.length
    },
    /**
     * 關閉編輯框
     */
    handleCancelEdit () {
      this.addCheckAll = false
      this.addIndeterminate = false
      this.addCheckedList = []
      this.editForm.resetFields()
      this.visibleEdit = false
    },
    /**
     * 編輯提交
     */
    editHandSubmit () {
      const { editForm: { validateFields } } = this
      this.editLoading = true
      validateFields((errors, values) => {
        addPlainOptions.forEach(item => {
          values[item] = 'N'
        })
        if (this.addCheckedList.length > 0) {
          this.addCheckedList.forEach(item => {
            values[item] = 'Y'
          })
        }
        if (!errors) {
          buttonEdit(values).then((res) => {
            this.editLoading = false
            if (res.success) {
              this.$message.success('編輯成功')
              this.getButtonList()
              this.handleCancelEdit()
            } else {
              this.$message.error('編輯失敗：' + res.message)
            }
          })
        } else {
          this.editLoading = false
        }
      })
    },
    /**
     * 下拉框選擇活動節點增加選擇活動節點名稱
     */
    actSelect (code) {
      this.addForm.getFieldDecorator('actName', { initialValue: this.UserTasks.find(item => code === item.id).name })
    },
    /**
     * 多選框點擊
     */
    addOnChange (checkedList) {
      this.addIndeterminate = !!checkedList.length && checkedList.length < addPlainOptions.length
      this.addCheckAll = checkedList.length === addPlainOptions.length
    },
    /**
     * 全選
     */
    addOnCheckAllChange (e) {
      Object.assign(this, {
        addCheckedList: e.target.checked ? this.columnsButtonTitle : [],
        addIndeterminate: false,
        addCheckAll: e.target.checked
      })
      const obj = JSON.parse(JSON.stringify(this.addCheckedList))
      if (this.addCheckedList.length > 0) {
        this.addCheckedList = []
        obj.forEach(item => {
          this.addCheckedList.push(item.dataIndex)
        })
      }
    },
    /**
     * 刪除
     */
    handleDel (record) {
      this.tableLoading = true
      buttonDelete({ id: record.id }).then((res) => {
        this.tableLoading = false
        if (res.success) {
          this.$message.success('刪除成功')
          this.getButtonList()
        } else {
          this.$message.error('刪除失敗：' + res.message)
        }
      })
    }
  }
}
