import { axios } from '@/utils/request'

/**
 * 添加活動節點按鈕
 *
 * @author yubaoshan
 * @date 2020/8/11 20:45
 */
export function buttonAdd (parameter) {
  return axios({
    url: '/flowableButton/add',
    method: 'post',
    data: parameter
  })
}

/**
 * 刪除活動節點按鈕
 *
 * @author yubaoshan
 * @date 2020/8/11 20:45
 */
export function buttonDelete (parameter) {
  return axios({
    url: '/flowableButton/delete',
    method: 'post',
    data: parameter
  })
}

/**
 * 編輯活動節點按鈕
 *
 * @author yubaoshan
 * @date 2020/8/11 20:45
 */
export function buttonEdit (parameter) {
  return axios({
    url: '/flowableButton/edit',
    method: 'post',
    data: parameter
  })
}

/**
 * 查看活動節點按鈕
 *
 * @author yubaoshan
 * @date 2020/8/11 20:45
 */
export function buttonDetail (parameter) {
  return axios({
    url: '/flowableButton/detail',
    method: 'get',
    params: parameter
  })
}

/**
 * 根據流程定義查詢活動節點按鈕列表（用於定義配置按鈕）
 *
 * @author yubaoshan
 * @date 2020/8/11 20:45
 */
export function buttonList (parameter) {
  return axios({
    url: '/flowableButton/list',
    method: 'get',
    params: parameter
  })
}

/**
 * 根據任務id查詢當前活動節點按鈕
 *
 * @author yubaoshan
 * @date 2020/8/11 20:45
 */
export function buttonTrace (parameter) {
  return axios({
    url: '/flowableButton/trace',
    method: 'get',
    params: parameter
  })
}
